<template lang="pug">
.hm_zoom
  v-icon.animated.infinite.pulse(name='search-plus' size='0.5')
  slot
</template>

<script>
import 'vue-awesome/icons/search-plus'
export default { name: 'hm_zoom' }
</script>

<style lang="sass">
.hm_zoom
  position: relative
  .fa-icon
    position: absolute
    bottom: 5px
    right: 0
    height: 2rem
    width: 2rem
    opacity: 0.6
    color: purple
  &:hover .fa-icon
    opacity: 1

  img.frame
    cursor: zoom-in !important
    margin: 0
    padding: 0

.content-v-img img
  max-height: 90% !important
</style>
