<script>
import 'vue-awesome/icons/regular/question-circle'

import Vue from 'vue'
import VueAffix from 'vue-affix'
import VueScrollActive from 'vue-scrollactive'

Vue.use(VueAffix)
Vue.use(VueScrollActive)

export default {
  metaInfo: {
    title: 'Возможности и преимущества домашней бухгалтерии ХаниМани',
    meta: [
      { name: 'description', content: 'Описание возможностей домашней бухгалтерии ХаниМани' }
    ]
  },
  components:
    {
      hm_zoom: require('@/components/hm_zoom.vue').default
    }
}
</script>

<template lang="pug">
div
  section.hero.is-success
    .hero-body
      .container.content
        .title.is-2 Как работает система ХаниМани
        .subtitle.is-4 И как она поможет вам обрести контроль над своими деньгами
  section.section
    .container
      .columns
        .column.is-3
          affix.menu(:enabled='!HM.is_touch', :offset='{ top: 100, bottom: 100 }', relative-element-selector='#promo_about_content')
            scrollactive(:duration='800', :offset='80', active-class='is-active', bezier-easing-value='.5,0,.35,1')
              ul.menu-list(style='width: 210px;')
                li
                  a.scrollactive-item.is-active(href='#why')
                    v-icon.feature_image_small.has-text-success(name='regular/question-circle' scale='1.3')
                    i.icon-chevron-right
                    | Зачем вести учёт
                li
                  a.scrollactive-item(href='#accounts')
                    img.feature_image_small(src='./assets/about/about_wallet.png', alt='About wallet')
                    i.icon-chevron-right
                    | Счета и Конверты
                li
                  a.scrollactive-item(href='#process')
                    img.feature_image_small(src='./assets/about/about_process.png', alt='About process')
                    i.icon-chevron-right
                    | Доходы/Расходы
                li
                  a.scrollactive-item(href='#goals')
                    img.feature_image_small(src='./assets/about/about_svin.png', alt='About svin')
                    i.icon-chevron-right
                    | Финансовые цели
                li
                  a.scrollactive-item(href='#analyze')
                    img.feature_image_small(src='./assets/about/about_charts.png', alt='About charts')
                    i.icon-chevron-right
                    | Учёт и анализ
                li
                  a.scrollactive-item(href='#plans')
                    img.feature_image_small(src='./assets/about/about_arrows.png', alt='About arrows')
                    i.icon-chevron-right
                    | Планирование
                li
                  a.scrollactive-item(href='#mobile')
                    img.feature_image_small(src='./assets/about/mobile_icon.png', alt='Mobile icon')
                    i.icon-chevron-right
                    | В кармане
                li
                  a.scrollactive-item(href='#security')
                    img.feature_image_small(src='./assets/about/about_lock.png', alt='About lock')
                    i.icon-chevron-right
                    | Безопасность
                li
                  a.scrollactive-item.is-active(href='#support')
                    img.feature_image_small(src='./assets/about/about_support.png', alt='About support')
                    i.icon-chevron-right
                    | Люди
        .column.is-9.content
          #promo_about_content(style='padding-right: 20px;')

            //- section.hero.is-warning
            //-   .hero-body
            //-     .content.has-text-centered
            //-       .title.is-2 Хотите держать финансы под контролем?
            h2#why(style='margin-top: 0') Зачем вести учёт финансов с ХаниМани

            section.section(style='padding-top: 0;')
              .content: .columns.is-centered: .column
                .subtitle.is-5
                  ul
                    li
                      | Чтобы денег не только всегда хватало, но и удавалось откладывать
                      strong  серьёзные суммы
                    li Чтобы получалось быстрее копить деньги на что-то важное
                    li Замечать неважное и экономить на этом
                    li Избавиться от всего стресса, связанного с деньгами
                    li: strong  И всё это без насилия над собой, бюджетов и прочих жёстких рамок
              div
                .tile.is-ancestor
                  .tile.is-parent
                    .tile.is-child.message.is-dark.content
                      .message-header Учёт вчера
                      .message-body
                        p
                          //- v-icon.has-text-success(name='thumbs-up' scale='1.5')
                          strong  Тратьте деньги с умом:
                        ul(style='margin-top: 0; margin-left: 1em;')
                          li Куда ушли деньги
                          li Какие покупки полезны
                          li На чём можно сэкономить
                  .tile.is-parent
                    .tile.is-child.message.is-warning.content
                      .message-header Контроль сегодня
                      .message-body
                        p
                          //- v-icon.has-text-success(name='thumbs-up' scale='1.5')
                          strong  Всё под контролем:
                        ul(style='margin-top: 0; margin-left: 1em;')
                          li Сколько <b>потратить</b>
                          li Сколько <b>не трогать</b>
                          li Сколько <b>отложить</b>
                  .tile.is-parent
                    .tile.is-child.message.is-success.content
                      .message-header Планирование завтра
                      .message-body
                        p
                          //- v-icon.has-text-success(name='thumbs-up' scale='1.5')
                          strong  Будущее прекрасно:
                        ul(style='margin-top: 0; margin-left: 1em;')
                          li Деньги на все расходы
                          li Достигайте целей
                          li Откладывайте больше

            hr#accounts

            img.feature_image(alt='Счета и Конверты в домашней бухгалтерии ХаниМани', src='./assets/about/about_wallet.png', width='100', height='100')
            h2 Счета и Конверты в системе
            p
              strong  Счета
              |  — это кошелёк, счёт в банке, кредитная карта, валенок на шкафу. Система поддерживает множество валют.
            p
              strong  Конверты
              |  — это те же самые деньги на счетах, но разбитые на 3 кучки — Резервы, Сбережения и ХаниМани.
            ul
              li
                span(style='color: #00c7c9;') Резервы
                |  — деньги, отложенные на будущие расходы с учётом будущих доходов.
              li
                span(style='color: #9bc600;') Сбережения
                |  — деньги на крупные финансовые цели (Резервный Фонд, ипотека и т.п.)
              li
                span(style='color: #eec400;') ХаниМани
                |  — деньги, которые можно тратить на незапланированные расходы или пополнить Сбережения.
            p
              | Система просчитывает запланированные доходы/расходы на год вперёд и даёт совет, как лучше распределить деньги, чтобы и на все расходы хватило, и все финансовые цели были достигнуты вовремя.
            .has-text-centered
              img.il_frame(alt='Счета и Конверты в системе', src='./assets/about/about_money.png', width='636', height='282')
              .help-block Счета и Конверты в системе
            .testimonial-container.left.testimonial-blue
              blockquote.testimonial
                p
                  | Мне больше всего нравится фундамент системы, архитектура, на которой можно построить оптимальную «ХаниМани». Т.е. очень в карту конверты, разбитие их на свободные (ханимани), зарезервированные (бюджет), сбережения (цели).
                  strong  Очень просто — но очень гениально
                  | . Аналогично очень удобно планирование бюджета, расходы очень удобно и эффективно планируются, при этом сразу подвязываются к конверту резервов. Ням-нямка
              .testimonial-arrow-down
              p.testimonial-author
                | Александр
                span  Киев
            hr#process
            img.feature_image(alt='Доходы и расходы в домашней бухгалтерии ХаниМани', src='./assets/about/about_process.png', width='100', height='100')
            h2 Доходы и расходы
            p
              | Чтобы система начала работать нужно запланировать все будущие доходы и расходы, и подтверждать осуществившиеся.
            p Не пугайтесь, это быстро и просто.
            p
              | Правда, 2-3 минуты в день уделить всё же придётся, но ведь вы готовы на это, правда? Уверенность в завтрашнем дне, спокойствие и защищённость стоят того, чтобы взять себя в руки и заняться управлением своими деньгами.
            .has-text-centered
              img.il_frame(alt='Запланированные и текущие доходы и расходы', src='./assets/about/about_calendar.jpg', width='636', height='464')
              .help-block Удобный, наглядный календарь доходов и расходов
            .testimonial-container.left.testimonial-green
              blockquote.testimonial
                p
                  | Нравится возможность внесения регулярных платежей, удобно для планирования и наглядно.
              .testimonial-arrow-down
              p.testimonial-author
                | Наталья
                span  Кривой Рог
            .testimonial-container.right.testimonial-green
              blockquote.testimonial
                p
                  | Домашняя бухгалтерия ХаниМани заменила то, что я делала 3 года вручную — учет ежедневных расходов в специально отведенной для этого тетради, в программе намного удобнее.
              .testimonial-arrow-down
              p.testimonial-author Нина
            hr#goals
            img.feature_image(alt='Финансовые цели в ХаниМани', src='./assets/about/about_svin.png', width='100', height='100')
            h2 Финансовые цели
            p Наличие чётко поставленных целей — признак финансово грамотных людей.
            p
              | Финансовая цель = кредит «наоборот». Вместо того, чтобы залезать в долги и переплачивать по процентам, вы планируете заранее крупные траты и накапливаете потихоньку деньги на них.
            p С ХаниМани этот процесс лёгок и безболезненен.
            .has-text-centered
              img.il_frame(alt='Финансовые цели в ХаниМани', src='./assets/about/about_goals.png', width='459', height='362')
              .help-block Планирование финансовой цели в ХаниМани
            .testimonial-container.right.testimonial-green
              blockquote.testimonial
                p
                  | С программами учета личных финансов уже давно столкнулся и многие перепробовал. Но главный принцип работы, который мне нужен был, давали только несколько, в том числе и ХаниМани! Суть в том, что оценить на что идут большие траты, какая статья расходов весомее другой, я и сам могу, считая и записывая доходы и расходы. Но планировать на будущее, оценивать влияние той или иной траты/дохода на конечный оборот денег, видеть целесообразность в тех или иных движениях средств (например, большая покупка через пол года-потяну ли?) — вот что меня беспокоило. Сейчас 99% своих мыслительных калькуляций провожу в ХаниМани и очень доволен. А значит
                  strong  могу уверенно (что я и делаю постоянно) советовать данный сайт
                  | .
              .testimonial-arrow-down
              p.testimonial-author
                | Максим
                span  (почти 2 года с ХаниМани)
            hr#analyze
            img.feature_image(alt='Регистрация в домашней бухгалтерии ХаниМани', src='./assets/about/about_charts.png', width='100', height='100')
            h2 Учёт и анализ
            p Красивые и понятные графики намного приятнее для глаз, чем горы цифр.
            p
              | Графики в ХаниМани помогают учитывать доходы и расходы, общее состояние счетов и денежный поток.
            img.feature_image.il_frame(alt='Отчёты и графики в ХаниМани', style='width: 250px;', src='./assets/about/about_treemap.jpg', width='256', height='135')
            p
              | График доходов и расходов по категориям наглядно покажет, куда же уходят деньги. И откуда они приходят.
            p Для отслеживания можно выбрать любой временной период.
            .has-text-centered
              img.il_frame(alt='Отчёты и графики в ХаниМани', src='./assets/about/about_stats.png')
              .help-block Отчёты и графики в ХаниМани
            .testimonial-container.left.testimonial-green
              blockquote.testimonial
                p
                  | Мне очень нравится ХаниМани простотой, удобством и тем, что можно разгрузить мозг и точно знать, что денег на всё хватит, т.к. система всё рассчитала. Также можно посмотреть статистику по тратам и проанализировать, на чём в будущем можно сэкономить. В целом
                  strong  понимаешь, куда утекают деньги
                  | , и есть ощущение, что всё под контролем, что для меня очень важно.
              .testimonial-arrow-down
              p.testimonial-author
                | Евгения
                span (с ХаниМани с первого дня существования системы)
            .testimonial-container.right.testimonial-orange
              blockquote.testimonial
                p Наиболее полезная для нас функция просмотра статистики по категориям.
              .testimonial-arrow-down
              p.testimonial-author
                | Артем и Анна
                span Санкт-Петербург
            hr#plans
            img.feature_image(alt='Регистрация в домашней бухгалтерии ХаниМани', src='./assets/about/about_arrows.png', width='100', height='100')
            h2 Планирование в ХаниМани
            p
              | Наверное, каждый человек прикидывает свои доходы/расходы на ближайшее будущее. Кто-то подробно, кто-то нет, кто-то на больший срок, кто-то на ближайшее время.
            p
              | А зачем держать все эти расчеты в голове? Компьютер может сделать это быстрее, точнее и на гораздо больший срок. К тому же, чем менее забита голова бытовухой, тем больше жизненных сил на действительно важные вещи.
            p
              | Почему бы просто не просчитать все запланированные доходы/расходы на какой-то промежуток времени вперед и определить, уходим ли мы когда-нибудь в минус (то есть существует недостаток денег) или же мы всегда в плюсе (то есть избыток денег). И если недостаток или избыток — то каковы они.
            p
              | Именно в этом
              strong  расчёте будущего денежного потока
              |  и заключается принцип планирования ХаниМани — просчитать на год вперёд ваши запланированные доходы и расходы.
            p
              | И подсказать вам, сколько денег должно быть заРезервировано, чтобы и на все будущие траты хватило, и финансовые цели были достигнуты как запланировано.
            .has-text-centered
              img.il_frame(width='700', alt='Денежный поток в ХаниМани', src='./assets/forecast.gif')
              .help-block Денежный поток в ХаниМани
              h3 Вы планируете — компьютер считает. Быстро и без ошибок
            .testimonial-container.right.testimonial-green
              blockquote.testimonial
                p
                  | Нравится именно то, ради чего ХаниМани задуман — понять, как будут распределены деньги в обозримом будущем. Благодаря ХаниМани я поняла, как надо перегруппировать расходы, чтобы не было финансовых дыр, когда до прихода денег 2 дня, а в кошельке пусто.
              .testimonial-arrow-down
              p.testimonial-author
                | Евгения
                span Москва
            hr#mobile
            img.feature_image(alt='Регистрация в домашней бухгалтерии ХаниМани', src='./assets/about/mobile_icon.png', width='100', height='100')
            h2 ХаниМани в Кармане
            p Вносите доходы и расходы на ходу, ведь ХаниМани — всегда у вас в кармане. Просто зайдите в систему через мобильный браузер и добавьте иконку ХаниМани на экран телефона.
            p ХаниМани — полноценное прогрессивное веб-приложение (PWA) с оффлайн-работой.
            p.has-text-centered
              .columns.is-gapless.is-mobile
                .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/accounts_envelopes.png')
                .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/form.png')
                .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/list.png')
                .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/stats.png')
                .column: hm_zoom: img.il_frame(v-img:mobile='{thumbnails: true}', src='./assets/mobile/reports.png')
            .testimonial-container.right.testimonial-blue
              blockquote.testimonial
                p
                  | Отлично!!!!! Пользуюсь ХаниМани уже не первый год, очень довольна. Очень ждала мобильный сайт, наконец дождалась. Уже всё попробовала, всё работает.
              .testimonial-arrow-down
              p.testimonial-author Светлана
            hr#security
            img.feature_image(alt='Регистрация в домашней бухгалтерии ХаниМани', src='./assets/about/about_lock.png', width='100', height='100')
            h2 Надёжность, безопасность, анонимность
            p
              | ХаниМани использует те же технологии  защиты информации, что и онлайн-банки (
              strong шифрование, SSL-256bit, резервное копирование
              | ).
            p
              | При оплате банковской картой,
              strong  данные карты никогда не попадают на сервера ХаниМани
              | , а отправляются напрямую платёжному процессору. Это позволяет не хранить у себя на сервере такую важную информацию.
            p
              | Так как сервису ХаниМани не интересны ваши персональные данные, нет возможности связать данные в системе и реального человека. Для регистрации достаточно только емэйла и пароля.
            p
              | Для пущей безопасности можете не использовать свой основной емэйл. Заведите себе дополнительный —
              a(href='http://mail.yandex.ru/', target='_blank') дел на 5 минут
              | . Что-нибудь типа
              strong  pchelka79@gmail.com
              |  позволит и быть анонимным, и получать пользу от ХаниМани.
            p
              | Данные о пользователе, которые хранит сервис, не позволяют идентифицировать
              strong  личность человека
              | .
            p
              | Сервис
              strong  НЕ предоставляет и НЕ обязан
              |  предоставлять никакую личную информацию
              br
              |  о пользователях третьим сторонам:
            ul
              li ни рекламодателям (мы не продаём рекламы),
              li ни налоговой,
              li
                | ни государственным органам (ХаниМани вне юрисдикции России — компания зарегистрирована
                strong  в Канаде
                | ).
            p
              | Сервис размещается на
              strong  надёжной инфраструктуре
              |  лидера рынка Amazon, резервное копирование сохраняет все данные каждый день на другой сервер Amazon’a.

            hr#support

            img.feature_image(src='./assets/about/about_support.png', alt='About support', width='100', height='100')
            h2 Дружелюбная, отзывчивая и заботливая поддержка
            p
              | Этот пункт неспроста самый первый в списке. Поддержка пользователей — на первом месте в ХаниМани.
            p
              | Есть вопросы по системе или по учёту/планированию личных финансов в целом? Просто напишите на
              a(href='mailto:bee@hmbee.ru')  bee@hmbee.ru
              | .
            p Вы всегда получите скорый и развёрнутый ответ от живого человека.
            p Серьёзно, попробуйте сами! :)
            .testimonial-container.left.testimonial-green
              blockquote.testimonial
                p
                  | ХаниМани постоянно дорабатывают и вносят дополнения. Очень привлекает
                  strong  живое общение с владельцем сайта.
              .testimonial-arrow-down
              p.testimonial-author Fly-Lady
            .testimonial-container.right.testimonial-yellow
              blockquote.testimonial
                p
                  | Больше всего в системе мне нравится обратная связь с разработчиками.
                  strong  На все мои письма
                  |  с просьбами о помощи или улучшении мне
                  strong  тут же отвечают и помогают
                  |  решить вопрос. Если б ХаниМани завтра не стало, то моя жизнь стала бы похожа на хаос, так как я использую систему в качестве напоминаний о предстоящих платах по счетам, что, в свою очередь, помогает мне отслеживать моё финансовое благополучие в будущем.
              .testimonial-arrow-down
              p.testimonial-author
                | Александр
                span  (почти 1.5 года с ХаниМани)

            h3 Наши клиенты — самое ценное, что у нас есть!
            .columns
              .column.has-text-right
                router-link.button.is-large.is-success.is-outlined(to='/ru/testimonials') Отзывы о ХаниМани »
</template>
